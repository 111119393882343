import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import InfoBar from '../../components/Utilities/InfoBar'
import SubPageMenu from '../../components/Utilities/SubPageMenu'

class CumulativeAgeStats extends React.Component {

  render() {

    let mdata = this.props.data.allSheetMessiAllTimeStats.edges
    let rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    let ages = rdata.map(d => d.node.competition).reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], [])

    ages = ages.reverse()

    return (

      <Layout>
        <SEO
          title={"Cumulative Stats by Age - Messi vs Ronaldo"}
          description={`Messi and Ronaldo are two years apart, so how would their career stats look if you put them side by side in terms of ages?`}
          canonicalPath={`/all-time-stats/stats-by-age-cumulative/`}
        />

        <h1>
        Cumulative Stats by Age <span className="sr-only">- Messi vs Ronaldo</span>
        </h1>

        

        <SubPageMenu
          pages={[
            { 'name': 'Totals Per Age', 'url': '/all-time-stats/stats-by-age/' },
            { 'name': 'Cumulative Stats by Age', 'url': 'none', 'active': true },
          ]}
        />

        <div className="w-full p-4 max-w-screen-xs mx-auto mt-4 sm:mt-6 md:mt-8">
          <InfoBar classes="w-full justify-center" msg="Messi's Birthday: 24 June 1987" />
          <InfoBar classes="w-full justify-center mt-2" msg="Ronaldo's Birthday: 5 February 1985" />
        </div>

        { ages.map(age => (
            <CompetitionBlock competition={age} type="all" mdata={mdata.filter(d => d.node.competition === age )} rdata={rdata.filter(d => d.node.competition === age )} subtitle={`Career stats on turning ${age.replace('By Age ','')} years old`} showPerfStats="no" />
          ))
        }

        <LastUpdated type="Stats" />
        
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/by-age/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/by-age/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
        }
      }
    }
  }
`

export default CumulativeAgeStats
